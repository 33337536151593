<template>
  <div class="vehicle-type-create-page container" v-if="currentAccount">
    <h1>Create vehicle type</h1>
    <hr />

    <form @submit.prevent="create">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" />
      </div>

      <div class="mb-3">
        <label for="color" class="form-label">Color</label>
        <input type="color" class="form-control" v-model="color" />
      </div>

      <button class="btn btn-primary" :disabled="creating">
        <template v-if="!creating">Create</template>
        <template v-else><i class="fas fa-circle-notch fa-spin"></i></template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createVehicleType } from '@/api';

export default {
  name: 'VehicleTypeCreatePage',
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    name: '',
    color: '',

    creating: false,
  }),
  methods: {
    async create() {
      if (this.creating) {
        return;
      }

      if (!this.name) return this.$toast.error("Name can't be empty.");
      if (!this.color) return this.$toast.error("Color can't be empty.");

      try {
        this.creating = true;

        const response = await createVehicleType({
          name: this.name,
          color: this.color,
        });
        if (response.errors) {
          throw new Error(response.errors[0].message);
          return;
        }

        this.$toast.success('Vehicle type successful created.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'vehicle-types' }), 2000);
      } catch (err) {
        this.$toast.error(err.message);
        this.creating = false;
      }
    },
  },
  mounted() {
    this.creating = false;
  },
};
</script>

<style scoped>
.vehicle-type-create-page {
  padding: 2em 0;
}
</style>
